import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { useCartState } from '../../../contexts/cart';
import { useUserContext } from '../../../aem-core-components/context/UserContext';
import useCheckout from '../../../hooks/useCheckout';
import { getReviewDuplicatesAction } from '../../../aem-core-components/actions/user';
import { useFilterState } from '../../cap';
import { EditableView } from '../../global/modules/stepForm';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';
import './editCreateJobsite.scss';
import Button from '../../global/atoms/button';
import EditButton from '../../global/atoms/editButton/EditButton';
import Input from '../../global/atoms/input';
import { LoadingIndicator } from '../../../aem-core-components';
import {
    validateAccessNotes,
    validateJobSite,
    validatePONumber,
    validatePrimaryContact
} from '../../global/utils/commonUtils';
import mutation_review_duplicates from '../../../aem-core-components/queries/mutation_review_duplicate_projects.graphql';
import { createFullAddress } from '../../cap/utils/capHelper';
import PhoneNumber from '../../global/modules/formFields/phoneNumber';
import {
    ADDERESS_NOT_VALIDATED,
    INVALID_PHONE_NUMBER,
    PROJECT_ACCESS_NOTES_ID,
    PROJECTSITE_ALREADY_EXISTS
} from '../constants';
import TextArea from '../../global/atoms/textArea';
import {
    RESET_LOCATION_DATA,
    SET_CREDIT_NEWADDR_FLAG,
    UPDATE_PROJECT
} from '../../../aem-core-components/actions/constants';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { logError } from '../../global/utils/logger';
import ReviewDuplicates from './reviewDuplicates/ReviewDuplicates';

const EditCreateJobsite = props => {
    const intl = useIntl();
    const headingRef = useRef(null);
    const [{ userAccount, isCreditNewAddress, isLoading }, dispatch] = useCartState();
    const [{ viewCart, projectDetails }, filterDispatch, { setSettingsCookieFunc }] = useFilterState();
    const [userState, { getAccountProjectsDotCom, addProject }] = useUserContext();
    const { handleEditInCart } = useCheckout();
    const [reviewDuplicatesGQL] = useMutation(mutation_review_duplicates);
    const { accountProjects } = userState;
    const { currentStep, handleStepChange, formStep } = props;
    const [jobsiteName, setJobsiteName] = useState(projectDetails?.projectName || '');
    const [jobsiteNameError, setJobsiteNameError] = useState('');
    const [isValidProjectName, setIsValidProjectName] = useState(false);
    const [primaryContact, setPrimaryContact] = useState(projectDetails?.primaryContactName || '');
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(projectDetails?.phoneNumber || '');
    const [validPhoneNumber, setValidPhoneNumber] = useState(false);
    const [accessNotes, setAccessNotes] = useState(projectDetails?.accessNotes || '');
    const [poNumber, setPONumber] = useState(projectDetails?.poNumber || '');
    const [primaryContactError, setPrimaryContactError] = useState('');
    const [accessNotesError, setAccessNotesError] = useState('');
    const [poNumberError, setPoNumberError] = useState('');
    const [submit, setSubmit] = useState(false);
    const [newProject, setNewProject] = useState({});
    const [duplicates, setDuplicates] = useState([]);
    const [selectedProject, setSelectedProject] = useState({});
    const [showReviewDuplicates, setShowReviewDuplicates] = useState(false);
    const [isNewProject, setIsNewProject] = useState(true);
    const [error, setError] = useState('');
    const [validAccessNotes, setValidAccessNotes] = useState(true);
    const [validPONumber, setValidPONumber] = useState(true);
    const [validPrimaryContact, setValidPrimaryContact] = useState(false);

    useEffect(() => {
        if (userAccount?.accountNumber) {
            if (accountProjects?.length === 0) {
                getAccountProjectsDotCom(userAccount?.accountNumber);
            }
        }
    }, [userAccount?.accountNumber]);

    const onChangeProjectName = text => {
        setJobsiteName(text.target.value);
        if (text.target.value) {
            setJobsiteNameError('');
            setIsValidProjectName(true);
        }
        setSubmit(false);
    };

    const onChangePrimaryContact = text => {
        if (validatePrimaryContact(text.target.value)) {
            setPrimaryContact(text.target.value);
            setPrimaryContactError('');
        }
        setSubmit(false);
    };

    const onChangeAccessNotes = text => {
        if (validateAccessNotes(text.target.value)) {
            setAccessNotes(text.target.value);
            setAccessNotesError('');
        }
    };

    const onChangePONumber = text => {
        if (validatePONumber(text.target.value)) {
            setPONumber(text.target.value);
            setPoNumberError('');
        }
    };

    const handlePhoneNumberChange = text => {
        setSelectedPhoneNumber(text.target.value);
        setSubmit(false);
    };

    const validateJobsiteInfo = () => {
        let isValid = true;
        if (!jobsiteName) {
            setJobsiteNameError(intl.formatMessage({ id: 'common:required-input-error' }));
            isValid = false;
            setIsValidProjectName(false);
        } else {
            if (!validateJobSite(jobsiteName)) {
                isValid = false;
                setIsValidProjectName(false);
                setJobsiteNameError(intl.formatMessage({ id: 'create-jobsite:special-characters-not-allowed' }));
            } else {
                setJobsiteNameError('');
                setIsValidProjectName(true);
            }
        }
        if (!primaryContact) {
            setPrimaryContactError(intl.formatMessage({ id: 'common:required-input-error' }));
            isValid = false;
        } else {
            if (!validatePrimaryContact(primaryContact)) {
                isValid = false;
                setValidPrimaryContact(false);
                setPrimaryContactError(intl.formatMessage({ id: 'common:input-format-error' }));
            } else {
                setValidPrimaryContact(true);
                setPrimaryContactError('');
            }
        }

        if (accessNotes) {
            if (!validateAccessNotes(accessNotes)) {
                isValid = false;
                setValidAccessNotes(false);
                setAccessNotesError(intl.formatMessage({ id: 'common:input-format-error' }));
            } else {
                setValidAccessNotes(true);
                setAccessNotesError('');
            }
        }

        if (poNumber) {
            if (!validatePONumber(poNumber)) {
                isValid = false;
                setValidPONumber(false);
                setPoNumberError(intl.formatMessage({ id: 'common:input-format-error' }));
            } else {
                setValidPONumber(true);
                setPoNumberError('');
            }
        }
        return isValid;
    };

    const showReviewScreen = duplicateProjects => {
        const newProject = {
            jobNumber: 'new',
            jobName: jobsiteName,
            address1: isCreditNewAddress ? viewCart?.location?.split(',')[0] : projectDetails?.projectAddress1,
            address2: isCreditNewAddress ? viewCart?.jobSiteAddr2 : projectDetails?.projectAddress2,
            city: isCreditNewAddress ? viewCart?.jobSiteCity : projectDetails?.selectedProjectCity,
            state: isCreditNewAddress ? viewCart?.jobSiteState : projectDetails?.selectedProjectState,
            zip: isCreditNewAddress ? viewCart?.jobSiteZip : projectDetails?.selectedProjectZip
        }
        setNewProject(newProject);
        setDuplicates([...duplicateProjects]);
        setSelectedProject(newProject);
        toggleDuplicateView();
    };

    const onPressProject = project => {
        setSelectedProject(project);
        if (project?.jobNumber === 'new') {
            setIsNewProject(true);
        } else {
            setIsNewProject(false);
        }
    };

    const toggleDuplicateView = () => {
        setShowReviewDuplicates(!showReviewDuplicates);
    };

    const fetchDuplicatesProjects = async () => {
        try {
            dispatch({ type: 'beginLoading' });
            const data = await getReviewDuplicatesAction({
                reviewDuplicatesGQL,
                payloadForReviewDuplicates: { search: viewCart?.location },
                accountNumber: userAccount?.accountNumber
            });
            dispatch({ type: 'endLoading' });
            if (data?.length === 0) {
                createProject();
            } else {
                showReviewScreen(data);
            }
        } catch (error) {
            logError(error, false, 'fetchDuplicatesProjects ');
        }
    };

    useEffect(() => {
        const fn = async () => {
            if (
                submit &&
                isValidProjectName &&
                validPhoneNumber &&
                validAccessNotes &&
                validPONumber &&
                validPrimaryContact
            ) {
                setSubmit(false);
                await fetchDuplicatesProjects();
            }
        };
        fn();
    }, [submit, isValidProjectName, validPhoneNumber, validAccessNotes, validPONumber, validPrimaryContact]);

    const submitForm = () => {
        setSubmit(true);
        validateJobsiteInfo();
    };

    const createNewProject = () => {
        if (isNewProject === true) {
            createProject();
        } else {
            filterDispatch({
                type: UPDATE_PROJECT,
                locationPC: selectedProject?.address[0]?.locationPC,
                projectName: selectedProject?.projectName,
                projectAddress1: selectedProject?.address[0]?.line1,
                selectedProjectJobId: selectedProject?.projectId,
                selectedProjectLatititude: selectedProject?.address[0]?.latitude,
                selectedProjectLongitude: selectedProject?.address[0]?.longitude,
                selectedProjectState: selectedProject?.address[0]?.state,
                selectedProjectCity: selectedProject?.address[0]?.city,
                selectedProjectZip: selectedProject?.address[0]?.zip,
                primaryContactName: selectedProject?.contact?.name,
                phoneNumber: selectedProject?.contact?.phone,
                accessNotes: selectedProject?.deliveryInstructions1,
                poNumber: selectedProject?.customerPO,
                CJDLVY: selectedProject?.CJDLVY
            });
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.CJDLVY, selectedProject?.CJDLVY);
            toggleDuplicateView();
            handleStepChange(formStep.HTGO);
        }
    };
    const createAddProjectPayload = () => {
        let delInstParts = accessNotes?.match(/.{1,40}/g) || [];
        let delInst1 = delInstParts[0];
        let delInst2 = delInstParts[1];
        let delInst3 = delInstParts[2];
        let delInst4 = delInstParts[3];

        let payload = {
            accountId: String(userAccount?.accountNumber),
            projectLocation: isCreditNewAddress ? viewCart?.location : createFullAddress(projectDetails),
            projectName: jobsiteName?.trim(),
            projectNumber: jobsiteName?.trim(),
            status: 'C',
            updateOpenHeader: 'N',
            deliveryInstructions1: delInst1 ? delInst1 : '',
            deliveryInstructions2: delInst2 ? delInst2 : '',
            deliveryInstructions3: delInst3 ? delInst3 : '',
            deliveryInstructions4: delInst4 ? delInst4 : '',
            poNumber: poNumber,
            address: {
                line1: isCreditNewAddress ? viewCart?.location?.split(',')?.[0] : projectDetails?.projectAddress1,
                line2: isCreditNewAddress ? viewCart?.jobSiteAddr2 : projectDetails?.projectAddress2,
                city: isCreditNewAddress ? viewCart?.jobSiteCity : projectDetails?.selectedProjectCity,
                state: isCreditNewAddress ? viewCart?.jobSiteState : projectDetails?.selectedProjectState,
                zip: isCreditNewAddress ? viewCart?.jobSiteZip : projectDetails?.selectedProjectZip,
                location: String(viewCart?.pc)
            },
            contact: {
                name: primaryContact,
                phoneNumber: selectedPhoneNumber
            }
        };
        return payload;
    };

    const createProject = async () => {
        try {
            dispatch({ type: 'beginLoading' });
            let payload = createAddProjectPayload();
            const { projectData, error, addressInfo } = await addProject(
                payload,
                localStorage.getItem('companyID') || 1
            );
            if (error) {
                setShowReviewDuplicates(false);
                switch (error.errorKey) {
                    case ADDERESS_NOT_VALIDATED:
                        setJobsiteNameError(
                            `${intl.formatMessage({ id: 'checkout:create-project-invalid-address' })} ${
                                addressInfo.addrMsg
                            }`
                        );
                        break;
                    case PROJECTSITE_ALREADY_EXISTS:
                        setJobsiteNameError(intl.formatMessage({ id: 'checkout:create-project-duplicate-project-name' }));
                        break;
                    default:
                        setError(`${error?.errorKey?.replaceAll('_', ' ') || error}`);
                        break;
                }
            } else {
                setError('');
                localStorage.setItem('isCreditNewAddress', false);
                filterDispatch({
                    type: UPDATE_PROJECT,
                    locationPC: projectData?.locationPC,
                    projectName: projectData?.jobName,
                    projectAddress1: projectData?.address1,
                    projectAddress2: projectData?.address2,
                    selectedProjectJobId: projectData?.jobNumber,
                    selectedProjectLatititude: projectData?.latitude,
                    selectedProjectLongitude: projectData?.longitude,
                    selectedProjectState: projectData?.state,
                    selectedProjectCity: projectData?.city,
                    selectedProjectZip: projectData?.zip,
                    primaryContactName: projectData?.contactName,
                    phoneNumber: projectData?.contactPhone,
                    accessNotes: projectData?.deliveryInstructions,
                    poNumber: projectData?.customerPO,
                    CJDLVY: projectData?.CJDLVY
                });
                localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.CJDLVY, projectData?.CJDLVY);
                dispatch({
                    type: SET_CREDIT_NEWADDR_FLAG,
                    isCreditNewAddress: false
                });
                filterDispatch({
                    type: RESET_LOCATION_DATA
                });
                if (showReviewDuplicates) {
                    toggleDuplicateView();
                }

                setSettingsCookieFunc(null, {
                    locationPC: projectData?.locationPC,
                    projectName: projectData?.jobName,
                    projectAddress1: projectData?.address1,
                    projectAddress2: projectData?.address2,
                    selectedProjectJobId: projectData?.jobNumber,
                    selectedProjectLatititude: projectData?.latitude,
                    selectedProjectLongitude: projectData?.longitude,
                    selectedProjectState: projectData?.state,
                    selectedProjectCity: projectData?.city,
                    selectedProjectZip: projectData?.zip,
                    primaryContactName: projectData?.contactName,
                    phoneNumber: projectData?.contactPhone,
                    accessNotes: projectData?.deliveryInstructions,
                    poNumber: projectData?.customerPO
                });
                handleStepChange(formStep.HTGO);
            }
            dispatch({ type: 'endLoading' });
        } catch (error) {
            logError(error, false, 'createProject ');
            dispatch({ type: 'endLoading' });
        }
    };

    const renderEditableView = () => {
        return (
            <>
                {isLoading && !showReviewDuplicates && (
                    <div className={'orderSummaryLoader'}>
                        <LoadingIndicator />
                    </div>
                )}
                <div className="address-container">
                    <label>{intl.formatMessage({ id: 'checkout:create-jobsite-address-text' })}</label>
                    {isCreditNewAddress ? (
                        <address>
                            {viewCart?.location?.split(',')[0]} <br />
                            {viewCart?.jobSiteCity}, {viewCart?.jobSiteState} {viewCart?.jobSiteZip}
                        </address>
                    ) : (
                        <address>
                            {projectDetails?.projectAddress1} <br />
                            {projectDetails?.selectedProjectCity}, {projectDetails?.selectedProjectState}
                            {projectDetails?.selectedProjectZip}
                        </address>
                    )}
                    <EditButton
                        testId={checkoutDatalocator.checkout_edit_cart_CTA_testid}
                        label={intl.formatMessage({ id: 'checkout:edit-in-cart' })}
                        handleOnClick={handleEditInCart}
                    />
                </div>

                <Input
                    name="jobsitename"
                    label={intl.formatMessage({ id: 'create-jobsite:jobsite-name-label' })}
                    value={jobsiteName}
                    maxLength="30"
                    handleInputChange={onChangeProjectName}
                    dataTestId={checkoutDatalocator.jobsiteNamePlaceHolder}
                    errorMsg={jobsiteNameError}
                    inputAriaLabel={intl.formatMessage({ id: 'create-jobsite:jobsite-name-placeholder' })}
                    required={true}
                    inputAriaRequired={true}
                    disclaimerMsg={intl.formatMessage({ id: 'create-jobsite:jobsite-message' })}
                />
                <Input
                    name="primaryContact"
                    label={intl.formatMessage({ id: 'create-jobsite:primary-contact-label' })}
                    value={primaryContact}
                    maxLength="30"
                    handleInputChange={onChangePrimaryContact}
                    lbltestid={checkoutDatalocator.primaryContactHelperText}
                    dataTestId={checkoutDatalocator.primaryContactPlaceHolder}
                    errorMsg={primaryContactError}
                    inputAriaLabel={intl.formatMessage({ id: 'create-jobsite:primary-contact-placeholder' })}
                    required={true}
                />
                <PhoneNumber
                    label={intl.formatMessage({ id: 'create-jobsite:mobile-number' })}
                    value={selectedPhoneNumber}
                    handleInputChange={handlePhoneNumberChange}
                    dataTestId={checkoutDatalocator.phoneNumberPlaceHolder}
                    onValidate={setValidPhoneNumber}
                    placeholder={''}
                    required={true}
                    submit={submit}
                    setSubmit={setSubmit}
                    inputAriaLabel={intl.formatMessage({ id: 'create-jobsite:phone-number-placeholder' })}
                />
                <TextArea
                    name={PROJECT_ACCESS_NOTES_ID}
                    value={accessNotes}
                    label={intl.formatMessage({ id: 'create-jobsite:access-notes-label' })}
                    handleChange={onChangeAccessNotes}
                    maxLength="160"
                    lbltestid={checkoutDatalocator.accessNotesHelperText}
                    dataTestId={checkoutDatalocator.accessNotesPlaceHolder}
                    errorMsg={accessNotesError}
                    textAriaLabel={intl.formatMessage({ id: 'create-jobsite:access-notes-placeholder' })}
                    fieldCustomClass="access-notes-text-box"
                />
                <small data-testid={checkoutDatalocator.letUsKnowIfThereAreText}>
                    {intl.formatMessage({ id: 'create-jobsite:instructions-let-us-know' })}
                </small>
                <Input
                    name="poNumber"
                    label={intl.formatMessage({ id: 'create-jobsite:po-number-label' })}
                    value={poNumber}
                    handleInputChange={onChangePONumber}
                    maxLength="26"
                    dataTestId={checkoutDatalocator.defaultPOnumberPlaceHolder}
                    errorMsg={poNumberError}
                    inputAriaLabel={intl.formatMessage({ id: 'create-jobsite:po-number-placeholder' })}
                    fieldcustomclass="poNumberTextBox"
                />
                <small data-testid={checkoutDatalocator.ifYouWantToUseText} className="defaultPONumberText">
                    {intl.formatMessage({ id: 'create-jobsite:default-po-number-text' })}
                </small>

                <Button
                    type="button"
                    onClick={submitForm}
                    className="button button-primary button-block createProjectButton"
                    buttonAriaLabel={intl.formatMessage({ id: 'checkout:create-jobsite-save-continue-cta' })}
                    data-testid={checkoutDatalocator.continueCTA}>
                    {intl.formatMessage({ id: 'checkout:create-jobsite-save-continue-cta' })}
                </Button>

                {showReviewDuplicates && (
                    <ReviewDuplicates
                        newProject={newProject}
                        duplicates={duplicates}
                        isNewProject={isNewProject}
                        handleClose={toggleDuplicateView}
                        createProject={createNewProject}
                        onPressProject={onPressProject}
                        showReviewDuplicates={showReviewDuplicates}
                        setShowReviewDuplicates={setShowReviewDuplicates}
                        isCreateProjectLoading={isLoading}
                    />
                )}
            </>
        );
    };
    return (
        <EditableView
            ref={headingRef}
            children={renderEditableView()}
            currentStep={currentStep}
            currentTitle={intl.formatMessage({ id: 'checkout:create-jobsite-title' })}
            testId={checkoutDatalocator.checkout_create_jobsite_testid}
        />
    );
};

export default EditCreateJobsite;
